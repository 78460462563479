import * as React from "react";
import * as Gatsby from "gatsby";
import Layout from "../../components/Layout";
import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    Link,
    ListItem,
    OrderedList,
    Tooltip,
    Text,
    Switch,
    useBoolean,
    FormControl,
    FormLabel,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalFooter, ModalBody
} from "@chakra-ui/react";
import {Helmet} from "react-helmet";
import Milestones from "../../components/nav/Milestones";

/*
2022年夏季计划以及导航内容。
一、计划
主要有几个目标：
1. 毕业设计：实验、论文、系统、报告
2. 秋招面试：算法（刷题与总结）、基础（经典图书）、进阶、笔面试复盘（高频考点、攻略）
3. 实习日志：项目情况、日记、经验
4. 英语口语：外企、会议
二、导航

 */

/**
 * 2022 Summer 开始的计划，所对应的导航菜单。
 * @returns {JSX.Element}
 * @constructor
 */
const Summer2022 = () => {
    const [flag, setFlag] = useBoolean();
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Layout>
            <Container minW={'80vw'}>
            <Container my={4}>
                <Helmet title={`${PAGE_TITLE} | Yuhan Tech`} defer={false} />
                <Heading as={'h1'} size='2xl' textAlign={'center'} id={'title'}>
                    {PAGE_TITLE}
                    <Text as={'span'} fontSize={12} ml={2} onClick={onOpen} _hover={{cursor: 'pointer'}}>
                        v{UPDATE_TIME}
                    </Text>
                </Heading>
            </Container>
            <Container textAlign={'center'} my={4}>
                {
                    PAGE_BUTTON.map((item) => (
                    <Tooltip hasArrow label={item.remark} bg='gray.300' color='black' offset={[0, 20]} key={item.to}>
                        <Gatsby.Link to={item.to}>
                            <Button m={4}>{item.label}</Button>
                        </Gatsby.Link>
                    </Tooltip>
                    ))
                }
            </Container>
            <Container mx={0} my={4}>
                <FormControl w={'80vw'} display={'flex'} justifyContent={'flex-end'}>
                    <FormLabel htmlFor='toggle-tips' mb='0'>提示</FormLabel>
                    <Switch id={'toggle-tips'} isChecked={flag} onChange={setFlag.toggle} />
                </FormControl>
                {
                    PAGE_NAV.map((item) => (
                        <Container mx={0} my={4} key={item.id}>
                            <Heading id={item.id} > {item.label} </Heading>
                            <Divider w={'80vw'}/>
                            {
                                flag && <OrderedList>{item.lists && item.lists.map((list, index) => <ListItem ml={4} mt={2} key={index}>{list}</ListItem>)}</OrderedList>
                            }
                            <Flex wrap={'wrap'} my={4} w={'80vw'} gap='2' justifyContent={'flex-start'}>
                                {item.children.map((child) => (
                                    <Link display="flex" href={child.href ?? 'javascript:void(0);'} isExternal={!!child.href} key={child.label} _hover={{textDecoration: 'none'}}>
                                        <Tooltip hasArrow label={child.remark ?? ''} bg='white' color='black' offset={[0, 10]}>
                                        <Box display="flex" alignItems='center' justifyContent='flex-start' width={300} boxShadow={'xs'} _hover={{boxShadow: 'lg'}} rounded={'lg'} p={4} m={2} textAlign={'center'}>
                                            <Avatar src={child.img}/>
                                            <Heading size={'md'} ml={'2'}> {child.label} </Heading>
                                        </Box>
                                        </Tooltip>
                                    </Link>
                                ))}
                            </Flex>
                        </Container>
                    ))
                }
            </Container>
            </Container>
            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Version History</ModalHeader>
                    <ModalBody>
                        <Milestones milestones={VERSION_HISTORY}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}


const PAGE_TITLE = '2022 Summer Time'

const UPDATE_TIME = '2022.6.6'

const VERSION_HISTORY = [
    {
        title: 'v2022.5.30',
        description: `建立起导航栏，融入 Chakra UI`,
        //date: '2022.5.30'
    },
    {
        title: 'v2022.5.31',
        description: `增加2022年夏季企划导航初始版本`,
       // date: '2022.5.31'
    },
    {
        title: 'v2022.6.2',
        description: `增加实习的内网导航说明、阿里云盘`,
    },
    {
        title: 'v2022.6.6',
        description: 'Things 的链接修复（多了\`），实习增加计划看板'
    }
]

VERSION_HISTORY.reverse();  // 倒序

const PAGE_BUTTON = [
    {
        label: '毕业设计',
        remark: '实验、论文、系统、报告',
        to: '#graduation'
    },
    {
        label: '秋招面试',
        remark: '算法（刷题与总结）、基础（经典图书）、进阶、笔面试复盘（高频考点、攻略）',
        to: '#interview'
    },
    {
        label: '实习日志',
        remark: '项目情况、日记、经验',
        to: '#practice'
    },
    {
        label: '英语口语',
        remark: '外企、会议',
        to: '#english'
    }
]

/*
注意：
1. 如果没有 href 则什么也不做。
 */
const PAGE_NAV = [
    {
        id: 'graduation',
        label: 'Graduation',
        lists: [
            '毕业论文 overleaf 上写',
            '机密内容用语雀',
            '公开内容写博客',
            '重要节点要记牢'
        ],
        children: [
            {
                label: '语雀（毕业设计）',
                img: 'https://gw.alipayobjects.com/zos/bmw-prod/735cefc9-f976-4c87-8b48-85f713f5b713.svg',
                remark: '有些东西不便于公开，但是可以写进我的论文',
                href: 'https://www.yuque.com/dfface/euz30k'
            },
            {
                label: 'Overleaf（毕业论文）',
                img: 'https://cdn.overleaf.com/images/overleaf-o-7052183c04d0611b79e2.svg',
                remark: '论文直接用 LaTeX 写，云端存储随时随地、防止丢失',
                href: 'https://www.overleaf.com/project/6109fc8e310e1a355c68ab52',
            },
            {
                label: 'kgd（毕业设计）',
                img: 'https://www.svgrepo.com/show/359756/college.svg',
                remark: '毕业设计所有文件都放在 projects -> kgd',
            }
        ],

    },
    {
        id: 'interview',
        label: 'Interview',
        lists: [
            '算法实践仍是重中之重',
            '框架核心理念及时补充',
            '牛客面经时刻警醒自己',
            '向优秀博客算法大神学习',
            '注意滚动复习与时常总结',
        ],
        children: [
            {
                label: '语雀（Web Clipper）',
                img: 'https://gw.alipayobjects.com/zos/bmw-prod/735cefc9-f976-4c87-8b48-85f713f5b713.svg',
                remark: '剪切了面试八股文，防止走丢',
                href: 'https://www.yuque.com/dfface/kykdtw'
            },
            {
                label: 'eBooks 电子书库',
                img: 'https://cdn.jsdelivr.net/gh/dfface/img0@master/2022/05-31-K39H3U-f1.png',
                remark: '有一些技术书可看，数据库在实验室Aha电脑或者移动硬盘',
                href: 'http://8.131.58.131:8809'
            },
            {
                label: '力扣（剑指题库）',
                img: 'https://leetcode.cn/favicon.ico',
                remark: '力扣CN，刷题必备，更有大神总结',
                href: 'https://leetcode.cn/problem-list/xb9nqhhg/'
            },
            {
                label: 'CodeTop（企业题库）',
                img: 'https://iph.href.lu/400x400?text=CT',
                remark: '面试前必刷高频算法题，更有智力题、设计题',
                href: 'https://codetop.cc/home'
            },
            {
                label: 'InterviewTop（企业题库）',
                img: 'https://iph.href.lu/400x400?text=IT',
                remark: '面试会问的题目，基础知识问答',
                href: 'http://interviewtop.top/#/list'
            },
            {
                label: '代码随想录',
                img: 'https://img-blog.csdnimg.cn/20210614201246512.png',
                remark: '系统性分类刷题，是剑指的补充',
                href: 'https://programmercarl.com/'
            },
            {
                label: 'labuladong 的算法小抄',
                img: 'https://labuladong.gitee.io/algo/images/avatar.png',
                remark: '手把手带你解决 200 道算法问题，插件很有趣',
                href: 'https://labuladong.gitee.io/algo/'
            },
            {
                label: 'CS Notes',
                img: 'https://cs-notes-1256109796.cos.ap-guangzhou.myqcloud.com/githubio/LogoMakr_0zpEzN.png',
                remark: '技术面试必备的基础知识，让你节省面试时间的',
                href: 'http://www.cyc2018.xyz/'
            },
            {
                label: 'Java Core',
                img: 'https://raw.githubusercontent.com/dunwu/images/dev/common/dunwu-logo-200.png',
                remark: 'Java 核心技术教程，可能的考点都在这里',
                href: 'https://dunwu.github.io/javacore/'
            },
            {
                label: 'Java 全栈知识体系',
                img: 'https://www.pdai.tech/_images/index-read.gif',
                remark: '东西很多，算法、数据库、开发、中间件、架构等',
                href: 'https://www.pdai.tech/'
            },
            {
                label: '牛客面经（Java开发）',
                img: 'https://uploadfiles.nowcoder.com/images/20190803/68_1564831402765_60BC9A593522790A16DEB4CAC66438A4?x-oss-process=image/resize,m_mfit,w_150,h_150',
                remark: '牛客Java开发的面经，瞅瞅就知道自己还差多少',
                href: 'https://www.nowcoder.com/discuss/experience?tagId=639'
            },
            {
                label: 'Notion Preparation（macOS）',
                img: 'https://cdn.worldvectorlogo.com/logos/notion-logo-1.svg',
                remark: '学习时需要记笔记，就用它无限嵌套',
                href: 'notion:/In-Preparation-b683dfc352224beba658bd01a5ff0929'
            },
            {
                label: 'Notion Summary（macOS）',
                img: 'https://cdn.worldvectorlogo.com/logos/notion-logo-1.svg',
                remark: '主要对学过的东西进行总结，以用于滚动复习，如算法题',
                href: 'notion:/21298e0575114c01a5c20d8c9e3a2742'
            },
            {
                label: '经典必读书目（Notion）',
                img: 'https://www.svgrepo.com/show/19554/book.svg',
                remark: '主要是技术书，需要读一读',
                href: 'https://www.notion.so/f330d63bbe8949ea92fac52a2070d370?v=0e10bd9675e34a218801b26cbb76cc8d'
            },
            {
                label: '阿里云盘（macOS）',
                img: 'https://gw.alicdn.com/imgextra/i3/O1CN01aj9rdD1GS0E8io11t_!!6000000000620-73-tps-16-16.ico',
                remark: '阿里云盘上有教程、图书等资源',
                href: 'smartdrive://show'
            }
        ]
    },
    {
        id: 'practice',
        label: 'Practice',
        lists: [
            '实习必须要写日志',
            '实习要积极主动抢任务做',
            '实习要注重每日汇报每周总结'
        ],
        children: [
            {
                label: '语雀（日记+看板）',
                img: 'https://gw.alipayobjects.com/zos/bmw-prod/735cefc9-f976-4c87-8b48-85f713f5b713.svg',
                remark: '实习的点点滴滴，一分一秒都记在这里',
                href: 'https://www.yuque.com/dfface/bmd917'
            },
            {
                label: '公司内网网页书签',
                img: 'https://lf-cdn-tos.bytescm.com/obj/static/atsx-fe/campus/favicon.ico',
                remark: '受公司数据安全限制，URL不在此展示，直接收藏于浏览器书签',
            },
        ]
    },
    {
        id: 'english',
        label: 'Oral English',
        lists: [
            '全力打造全英文环境',
            '尝试英文分享自己的生活',
            '学习英语口语发音',
            '学习场景英语对话，如雅思口语考试情景等'
        ],
        children: [
            {
                label: '海外兔',
                img: 'https://cdn.jsdelivr.net/gh/OSJobs/osjobs-base/img/rabbit-256.png',
                remark: '海内外工作的经验交流，有求职指南和面试题',
                href: 'https://osjobs.net/'
            }
        ]
    },
    {
        id: 'others',
        label: 'Others',
        children: [
            {
                label: '日历（macOS）',
                img: 'https://www.svgrepo.com/show/7026/calendar.svg',
                remark: '',
                href: 'webcal://'
            },
            {
                label: 'iTerm2（macOS）',
                img: 'https://www.svgrepo.com/show/354445/terminal.svg',
                remark: '',
                href: 'news://'
            },
            {
                label: 'Notion Diary（macOS）',
                img: 'https://cdn.worldvectorlogo.com/logos/notion-logo-1.svg',
                href: 'notion:/bfa7221478954f718da8d8ef24543b4d'
            },
            {
                label: 'Things（macOS）',
                img: 'https://culturedcode.com/favicon.ico',
                href: 'things:///show?query=收件箱'
            },
            {
                label: 'DevonThink（macOS）',
                img: 'https://www.devontechnologies.com/assets/images/favicon/favicon.ico',
                href: 'x-devonthink://search'
            }
        ]
    }
]

export default Summer2022;